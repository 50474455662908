import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './HeroSection.css';
import camelPC from '../../../assets/imgs/bg/camel_web.jpeg';
import camelMob from '../../../assets/imgs/bg/camel-mobile.jpeg';

import SoftwarePC from '../../../assets/imgs/bg/pexels-fauxels-web.jpeg';
import SoftwareMob from '../../../assets/imgs/bg/pexels-fauxels-mobile.jpeg';

import babPC from '../../../assets/imgs/bg/sirubabsectionn.jpg';
import babMob from '../../../assets/imgs/bg/sirubabsectionn.jpg';

import vision from '../../../assets/imgs/bg/vision.svg';
import HeaderTop from './Headertop';
import HeaderBottom from './HeaderBotttom';
import { useTranslation } from 'react-i18next';
const HeroSection = () => {
  const [componies, setComponies] = useState([]);
  const { t } = useTranslation();
  const windowWidth = useRef(window.innerWidth);

  useEffect(() => {
    setComponies([
      {
        name: "اذهب إلى سيروا للحلول البرمجية",
        title: t("our_companies.sirusolutions_title"),
        desc: t("our_companies.sirusolutions_brief"),
        link: "google.com",
        icon: SoftwareMob ,
        icon2: SoftwarePC 

      },
      {
        title: t("our_companies.siruhigin_title"),
        desc: t("our_companies.siruhigin_brief"),
        name: "اذهب إلى سيروا للهجن",
        link: "google.com",
      
        icon: camelMob,
        icon2: camelPC
      }
      // ,
      // {
      //   title: t("our_companies.sirubab_title"),
      //   desc: t("our_companies.sirubab_brief"),
      //   name: "اذهب إلى سيروا باب",
      //   link: "google.com",
      
      //   icon: babMob,
      //   icon2: babPC
      // }
    ])
  }, [t])
  return (

    <div className="heroSection" id='hero-section'>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        pagination={{ clickable: true, direction: 'vertical' }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
        loop={true}>
        {componies?.length > 0 && componies.map((item, index) =>
          <SwiperSlide key={index}>
            <div className="img-container">
              <HeaderTop
                head={item.title}
                text={item.desc}
              />
              <img src={`${windowWidth.current > 1025 ? item.icon2 : item.icon }`} alt="" className="slideOneBG" style={{ borderRadius: "0px" }} />
              <div className="vision-container">
                <img src={vision} alt="" />
              </div>
            </div>
          </SwiperSlide>)}
        <HeaderBottom
          head={t("unlimited_possibilities")}
          text={t("expertise_message")}
          text2={t("expertise_message2")}
        />
      </Swiper>
    </div>

  );
};

export default HeroSection;
