import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import Linkedin from '../../assets/imgs/icons/Linkedin.svg'
import Instagram from '../../assets/imgs/icons/Instagram.svg'
import Facebook from '../../assets/imgs/icons/Facebook.svg'
import YouTube from '../../assets/imgs/icons/youtube.png'
import XSocial from '../../assets/imgs/icons/x-social.png'
import TikTok from '../../assets/imgs/icons/tiktok.png'




import './MainAppFooter.scss';
// import mainAppBarLinks from '../main-app-bar/mainAppBarLinks';
import Logo from '../../common/logo/Logo';

const MainAppFooter = () => {
  const { t } = useTranslation();

  const hanldeClick = (section) => () => {
    // history.push('/');
    //
    document.querySelector(`.${section}`).scrollIntoView();
  };

  const { pathname } = useLocation();
  const renderNavLinks = () => {
    return (
      <ul>
        {/* {mainAppBarLinks(t, pathname).map(({ id, name, link, section }) => ( */}
        {/* onClick={hanldeClick(section)} */}
        <li  >
          <NavLink activeClassName="active-link" to={""} exact >

            {t("our_vision.title")}:
            <span style={{ fontSize: '14px' }}>  {t(" تتطلع الي تقديم خدمات مميزه بتعاملات رآقية")}
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li  >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("our_message.title")}:
            <span style={{ fontSize: '13px' }}>  {t("السعي الي المثالية في تقديم الجديد والعصري")}
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        {/* <li  >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("")}
            <span style={{ fontSize: '13px' }}>  {t("في عالم المال والآعمال")}
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li> */}
        <li  >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("our_goals.title")}:
            <span style={{ fontSize: '14px',width: '101%' }}>  {t("تحقيق رؤية عملآءنا التجارية وفق أعلي المعايير")}
           
            </span>
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        {/* ))} */}
      </ul>
    );
  };

  const renderNavComponies = () => {
    return (
      <ul>
        {/* {mainAppBarLinks(t, pathname).map(({ id, name, link, section }) => ( */}
        {/* onClick={hanldeClick(section)} */}
        <li >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("our_companies.sirusolutions_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("our_companies.sirubab_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("our_companies.siruhigin_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        <li >
          <NavLink activeClassName="active-link" to={""} exact >
            {t("our_companies.sirutech_title")}
            <div className="active-img-wrap"></div>
          </NavLink>
        </li>
        {/* ))} */}
      </ul>
    );
  }
  return (
    <>
      <footer className="main-app-footer">
        <div className="overlay-footer">
          <div className="custom-container">
            <div className="footer-content-wrap">
              <div className="footer-about footer-shared">
                <div className="wrapper-title">
                  <div className='footer-logo'>
                    <Logo />
                  </div>
                </div>
                <p className="footer-desc">{t('main_app_footer.footer_desc')}
                <br />
                <br />
                {t('main_app_footer.address')}
                </p>
                <div className="app-social-links">
                  <div className="social-links">

                  </div>
                </div>
              </div>
              <div className="footer-links footer-shared">
                <div className="wrapper-title">{t('main_app_bar_links.about_company')}</div>
                <ul className="nav-links-ul">{renderNavLinks()}</ul>
              </div>
              <div className="footer-links footer-shared">
                <div className="wrapper-title">{t('our_companies.title')}</div>
                <ul className="nav-links-ul">{renderNavComponies()}</ul>
              </div>

              <div className="footer-social footer-shared">
                <div className="wrapper-title">{t('contact_footer.title')}</div>
                <div className="social-emails-wrap">
                  {/* {t("contact_footer.address")}: {t("contact_footer.address_value")}
                  <br /> */}
                  {t("contact_footer.phone_no")}: {t("contact_footer.phoneNo_value")}
                  <br />
                  {t("contact_footer.email")}: {t("contact_footer.email_value")}
                  <ul>
                    
                    <li className=" font-lg"><a href="https://www.facebook.com/profile.php?id=61553350577597&mibextid=ZbWKwL" target="_blank" className="hover:underline hover:underline-offset-[3px]">
                      <img src={Facebook} alt="" srcset="" />

                    </a></li>
                    <li className=" font-lg"><a href="https://www.instagram.com/siru_maps?igsh=MXd2dTBobXA0NXY3bA==" target="_blank" className="hover:underline hover:underline-offset-[3px]">
                      <img src={Instagram} alt="" srcset="" />

                    </a></li>
                    <li className=" font-lg"><a href="https://www.youtube.com/channel/UC7RtsC8lg2n7DCZo3O0k7pw" target="_blank" className="hover:underline hover:underline-offset-[3px]">
                      <img style={{ width: "51px", height: "67px",marginRight: "-5px" }} src={YouTube} alt="" srcset="" />
                    </a></li>
                    <li className=" font-lg"><a href="https://x.com/siru_maps?t=2EitJXe6xdf378Lcb3_jQA&s=09" target="_blank" className="hover:underline hover:underline-offset-[3px]">
                      <img style={{marginRight: "-11px", width: "53px"}} src={XSocial} alt="" srcset="" />
                    </a></li>
                    <li className=" font-lg"><a href="https://www.tiktok.com/@siru_maps?_t=8icN2GWLMI6&_r=1" target="_blank" className="hover:underline hover:underline-offset-[3px]">
                      <img style={{marginRight: "-11px", width: "53px"}} src={TikTok} alt="" srcset="" />
                    </a></li>
                    {/* <li className=" font-lg"><a href="https://youtube.com/@SiruMaps?si=r9KY8yi3S5ND4Apn" target="_blank" className="hover:underline hover:underline-offset-[3px]"><span className="flex items-center gap-2">

                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0,0,256,256"
                        style={{ fill: "#000000" }}>

                        <g fill="#fcc419" fill-rule="nonzero" stroke="none"  ><g transform="scale(5.12,5.12)"><path d="M44.89844,14.5c-0.39844,-2.19922 -2.29687,-3.80078 -4.5,-4.30078c-3.29687,-0.69922 -9.39844,-1.19922 -16,-1.19922c-6.59766,0 -12.79687,0.5 -16.09766,1.19922c-2.19922,0.5 -4.10156,2 -4.5,4.30078c-0.40234,2.5 -0.80078,6 -0.80078,10.5c0,4.5 0.39844,8 0.89844,10.5c0.40234,2.19922 2.30078,3.80078 4.5,4.30078c3.5,0.69922 9.5,1.19922 16.10156,1.19922c6.60156,0 12.60156,-0.5 16.10156,-1.19922c2.19922,-0.5 4.09766,-2 4.5,-4.30078c0.39844,-2.5 0.89844,-6.10156 1,-10.5c-0.20312,-4.5 -0.70312,-8 -1.20312,-10.5zM19,32v-14l12.19922,7z"></path></g></g>                      </svg>
                      <p>Youtube</p>
                    </span>
                    </a></li>
                    <li className=" font-lg"><a href="https://www.tiktok.com/@siru_maps?_t=8icN2GWLMI6&_r=1" target="_blank" className="hover:underline hover:underline-offset-[3px]"><span className="flex items-center gap-2">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0,0,256,256"
                        style={{ fill: "#000000" }}>
                        <g fill="#fcc419" fill-rule="nonzero" stroke="none"  ><g transform="scale(5.12,5.12)"><path d="M41,4h-32c-2.757,0 -5,2.243 -5,5v32c0,2.757 2.243,5 5,5h32c2.757,0 5,-2.243 5,-5v-32c0,-2.757 -2.243,-5 -5,-5zM37.006,22.323c-0.227,0.021 -0.457,0.035 -0.69,0.035c-2.623,0 -4.928,-1.349 -6.269,-3.388c0,5.349 0,11.435 0,11.537c0,4.709 -3.818,8.527 -8.527,8.527c-4.709,0 -8.527,-3.818 -8.527,-8.527c0,-4.709 3.818,-8.527 8.527,-8.527c0.178,0 0.352,0.016 0.527,0.027v4.202c-0.175,-0.021 -0.347,-0.053 -0.527,-0.053c-2.404,0 -4.352,1.948 -4.352,4.352c0,2.404 1.948,4.352 4.352,4.352c2.404,0 4.527,-1.894 4.527,-4.298c0,-0.095 0.042,-19.594 0.042,-19.594h4.016c0.378,3.591 3.277,6.425 6.901,6.685z"></path></g></g>                      </svg>
                      <p>Ticktok</p>
                    </span>
                    </a></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <p className="copyPera">
                  {t("copy_right")}                </p>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>

  );
};

export default MainAppFooter;
