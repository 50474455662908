import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
// import { useHistory } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import LanguageButton from '../../common/language-button/LanguageButton';
import Logo from '../../assets/imgs/bg/header-logo.svg';
import mainAppBarLinks from './mainAppBarLinks';
import './MainAppBarLg.scss';
import routerLinks from '../app/routerLinks';
import PakedageContext from '../../contexts/pakedage-context/PakedageProvider';

// import { styled, alpha } from '@mui/material/styles';
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';



// function CustomizedMenus() {
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };
//   const { t } = useTranslation();
//   return (
//     <div>
//       <Button
//         id="demo-customized-button"
//         aria-controls="demo-customized-menu"
//         aria-haspopup="true"
//         aria-expanded={open ? 'true' : undefined}
//         variant="contained"
//         disableElevation
//         onClick={handleClick}
//         // endIcon={<KeyboardArrowDownIcon />}
//       >
//         {t('main_app_bar_links.Dashboard')}
//       </Button>
//       <StyledMenu
//         id="demo-customized-menu"
//         MenuListProps={{
//           'aria-labelledby': 'demo-customized-button'
//         }}
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}>
//         <MenuItem onClick={handleClose} disableRipple>
//           <a
//             href="https://fatoorah.fatoorah.sa"
//             target="_blank"
//             rel="noreferrer"
//             className="linkle">
//             {t(`request_project_page.request_project_form.activity_id.label2`)}
//           </a>
//         </MenuItem>
//         <MenuItem onClick={handleClose} disableRipple>
//           <a href="http://services.fatoorah.sa" target="_blank" rel="noreferrer" className="linkle">
//             {t(`request_project_page.request_project_form.activity_id.label3`)}
//           </a>
//         </MenuItem>
//       </StyledMenu>
//     </div>
//   );
// }

const MainAppBarLg = ({ className }) => {
  const { t } = useTranslation();
  // const history = useHistory();
  const { loadingPakedage } = useContext(PakedageContext)
  const windowWidth = useRef(window.innerWidth);

  const hanldeClick = (section) => () => {
    if (pathname === routerLinks.homePage && section !== '') {
      document.querySelector(`#${section}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // history.push(routerLinks.HelpFatorah, {});
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === routerLinks.contactPage) {
    } else {
    }
  }, [pathname]);

  const renderNavLinks = () => {
    return (
      <ul>
        {mainAppBarLinks(t, pathname).map(({ id, name, link, section }) => (
          <li
            key={id}
            // className={`${section === loadingPakedage
            //     ? 'Active'
            //     : 'notActive'
            //   }`}
            >
            <NavLink to={link} exact onClick={hanldeClick(section)}>
              {name}
              <div className="active-img-wrap"></div>
            </NavLink>
          </li>
        ))}
        {/* <li>
        <a
          href={`${windowWidth.current > 1025 ? "https://web.whatsapp.com/send?phone=966550570058" : "https://api.whatsapp.com/send?phone=966550570058"}`}
          target="_blank"
          rel="noreferrer">
          تواصل معانا
          </a>
        </li> */}
      </ul>
    );
  };
  return (
    <div className={className} id='main-app-bar'>
      {/* <div className=""> */}
      <div className="nav-content-wrapper">
        {/* <Logo className="main-app-bar-logo" /> */}
        <Link
          className={`main-app-bar-logo`}
          onClick={hanldeClick('hero-section')}
          to={routerLinks.homePage}>
          <div className="" style={{ display: "flex" }}>
            <img src={Logo} alt="app logo" className={` `} />
            {/* <img src={'/assets/imgs/logo/SiruText.png'} alt="app logo" className={``} /> */}
          </div>

        </Link>
        <div className="nav-lang-wrapper">
          {renderNavLinks()}
          {/* <a href="https://fatoorah.fatoorah.sa" target="_blank" rel="noreferrer">
              <button className="dashborad">{t('main_app_bar_links.Dashboard')}</button>
            </a> */}
          {/* <CustomizedMenus /> */}
          <LanguageButton />
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default MainAppBarLg;
