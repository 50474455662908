import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// import HeroSection from '../../components/home-page-components/HeroSection';

import DocTitleScrollTop from '../../utils/DocTitleScrollTop';

import './HomePage.scss';

import Cookies from 'js-cookie';
import OurPropose from './Home-compoents/OurPropose';
import OurMessage from './Home-compoents/OurMessage';
import OurVision from './Home-compoents/OurVision';
import OurComponies from './Home-compoents/OurComponies';
import HeroSection from './Home-compoents/HeroSection';

const HomePage = () => {
  DocTitleScrollTop();

  useEffect(() => {
    Cookies.remove('currentUser');
  }, []);
  return (
    <div className="home-page">
      <Helmet>
        <title>siru maps</title>
      </Helmet>
      <HeroSection />
        <OurVision />
        <OurMessage />
        <OurPropose />
        <OurComponies />
    </div>
  );
};

export default HomePage;
