import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import lngs from '../../languages';

import './LanguageButton.scss';
import routerLinks from '../../components/app/routerLinks';
import { useLocation } from 'react-router';

const LanguageButton = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);
  const { pathname } = useLocation();
  const renderLanguage = () => {
    const code = i18n.language;
    const filteredLngs = lngs.filter((lng) => lng.code !== code);
    return filteredLngs.map(
      ({ country_code, code, name, icon }, index) => (
        <span
          key={index}
          className={`lng-span-btn ${
            pathname === routerLinks.PolicyPage ||
            pathname === routerLinks.returnPage ||
            pathname === routerLinks.payPage ||
            pathname === routerLinks.ConditionPage ||
            pathname === routerLinks.contactPage
              ? 'Active'
              : 'notActive'
          }`}
          onClick={() => i18next.changeLanguage(code)}>
          <img src={icon} width="20"/>
        </span>
      )
      // <span
      // key={country_code}
      // onClick={() => i18next.changeLanguage(code)}
      // className={`flag-icon flag-icon-${country_code}`}></span>
    );
  };

  return <div className="main-lang-btn">{renderLanguage()}</div>;
};

export default LanguageButton;
