import React, { useRef } from 'react';
import './HeaderBottom.css';
const HeaderBottom = ({ head, text, text2 }) => {
  const windowWidth = useRef(window.innerWidth);

  return (
    <div className="swiper-bottom-box" >
      <h1>{head}</h1>

        {windowWidth.current > 1025 ?
              <div>
             <p>{text}</p>
             <l>{text2}</l>
             </div>
      : <div>
          <p>{text} {text2}</p>
        </div> }
   
    
    </div>
  );
};

export default HeaderBottom;
