import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  loadingPakedage: "hero-section",
  setLoadingPakedage: (v) => { },

};

const PakedageContext = createContext(INITIAL_VALUES);

export const PakedageProvider = ({ children }) => {
  const [loadingPakedage, setLoadingPakedage] = useState(INITIAL_VALUES.loadingPakedage);

  return (
    <PakedageContext.Provider
      value={{
        loadingPakedage,
        setLoadingPakedage,

      }}>
      {children}
    </PakedageContext.Provider>
  );
};

export default PakedageContext;
