import routerLinks from '../app/routerLinks';

const mainAppBarLinks = (t, pathname) => {
return [      {
        id: 1,
        name: t('main_app_bar_links.home'),
        link: routerLinks.homePage,
        section: 'hero-section'
      },
      // {
      //   id: 2,
      //   name: t('main_app_bar_links.about_us'),
      //   link: routerLinks.homePage,
      //   section: 'ourwork'
      // },
      {
        id: 4,
        name: t('main_app_bar_links.our_vision'),
        link: routerLinks.homePage,
        section: 'our_vision'
      },
      {
        id: 7,
        name: t('main_app_bar_links.our_message'),
        link: routerLinks.homePage,
        section: 'our_message'
      },
      {
        id: 8,
        name: t('main_app_bar_links.our_propose'),
        link: routerLinks.homePage,
        section: 'our_propose'
      },
     {
        id: 3,
        name: t('main_app_bar_links.companies'),
        link: routerLinks.homePage,
        section: 'our_componies'
      },
      
      // {
      //   id: 5,
      //   name: t('main_app_bar_links.get_in_touch'),
      //   link: routerLinks.homePage,
      //   section: 'contact-form'
      // }
      // {
      //   id: 3,
      //   name: t('main_app_bar_links.HelpFatorah'),
      //   link: routerLinks.HelpFatorah,
      //   section: ''
      // }
    ];

};

export default mainAppBarLinks;
