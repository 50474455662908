import './Logo.scss';

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';
import { useTranslation } from 'react-i18next';

const Logo = ({ className }) => {
  const { i18n } = useTranslation();

  const { pathname } = useLocation();
  const hanldeClick = (section) => () => {
    if (pathname !== routerLinks.homePage) {
    } else {
      document.querySelector(`.${section}`).scrollIntoView();
    }
  };
  return (
    <Link
      className={`${className} ${i18n.dir()}`}
      onClick={hanldeClick('hero-section')}
      to={routerLinks.homePage}>
      <div className="" style={{ display: "flex" }}>
        <img src={'/assets/imgs/logo/SMLOGO.svg'} alt="app logo" className={` `} />
        {/* <img src={'/assets/imgs/logo/SiruText.png'} alt="app logo" className={``} /> */}
      </div>

    </Link>
  );
};

export default Logo;
