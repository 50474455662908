import React from 'react'

import one from '../../../assets/imgs/bg/Mask.png'

import './OurMessage.scss'
import { useTranslation } from 'react-i18next'
const OurMessage = () => {
    const { t } = useTranslation()
    return (
        <div className='our-message' >

            <div id='our_message'></div>
            {/* <div style={{height: "20px" }}></div>
            <div style={{height: "20px" }}></div> */}
            <div className='our_message-div'>
                <div className="right-div" data-aos="zoom-in-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="200">
                    <p className='title'>{t("our_message.title")}</p>
                    <div className="desc">
                    {t("our_message.desc")}

                    </div>
                </div>
                <div className="left-div" data-aos="fade-out-bottom" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="200">
                    <img src={one} alt="" data-aos="zoom-in-left"/>
                </div>
            </div>
        </div>
    )
}

export default OurMessage