import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import logobab from '../../../assets/imgs/bg/sirubablogo.png';
import logo2 from '../../../assets/imgs/bg/header-logo.svg';

import './OurComponies.scss';
import arrow from '../../../assets/imgs/bg/blackArrow.png';
import greenBG from '../../../assets//imgs/bg/Companies.png';
import PrevBtn from '../../../assets/imgs/icons/leftArrow.svg';
import nextArrow from '../../../assets/imgs/icons/rightArrow.svg';
import logoCamel from '../../../assets/imgs/bg/SoftwareForMobile.jpg';
import logoRed from '../../../assets/imgs/bg/sirubabsection.jpeg';
import logoDoor from '../../../assets/imgs/bg/camelForMobile.jpg';
import { useTranslation } from 'react-i18next';
const OurComponies = () => {
  const swiperRef = useRef(null);
  const { t } = useTranslation();
  const [componies, setComponies] = useState([]);
  useEffect(() => {
    setComponies([
      {
        title: t("our_companies.sirutech_title"),
        desc: t("our_companies.sirutech_brief"),
        name: t("our_companies.sirutech_name"),
        link: "https://github.com/",
        logo: logo2,
        icon: logoDoor
      },
      {
        title: t("our_companies.siruhigin_title"),
        desc: t("our_companies.siruhigin_brief"),
  
        name: t("our_companies.siruhigin_name"),
        link: "https://github.com/",
        icon: logoDoor
      },
      {
        title: t("our_companies.sirubab_title"),
        desc: t("our_companies.sirubab_brief"),
    
        name: t("our_companies.sirubab_name"),
        link: "https://github.com/",
        icon: logoRed,
        logo: logobab,

      },
      {
        title: t("our_companies.sirusolutions_title"),
        desc: t("our_companies.sirusolutions_brief"),
 
        name: t("our_companies.sirusolutions_name"),
        link: "https://github.com/",
        icon: logoCamel
        
      },



    ])
  }, [t])

  return (
    <div className='bg-our-componies'>

      <div style={{ marginTop: "10px", height: "20px" }} id='our_componies'></div>
      <div style={{ marginTop: "10px", height: "20px" }}></div>
      <div style={{ marginTop: "10px", height: "20px" }}></div>

      <div className="our-componies" dir='ltr'>
        <div className="overlay-cc">
          <p className="title">{t("our_companies.title")}</p>

          <div className="componay-slider">
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              navigation={true}
              style={{
                '--swiper-navigation-color': '#000',
                '--swiper-pagination-color': '#000'
              }}
              slidesPerView={4}
              spaceBetween={10}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              modules={[Navigation, Autoplay]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 20
                },
                1025: {
                  slidesPerView: 3,
                  spaceBetween: 20
                },
                1300: {
                  slidesPerView: 4
                },
                1700: {
                  slidesPerView: 5,
                  spaceBetween: 20
                }
              }}
              className="mySwiper">
              {componies?.length > 0 && componies.map((item, index) =>
                <SwiperSlide key={index}>
                  <div className="one-compony">
                    <div className="img">
                      <img src={item.icon} alt="" className='main-img' />
                      {item.logo ? <img src={item.logo} alt="" className='logo-icon' /> : ""}
                      
                    </div>
                    <div className="text-desc">
                      <div className="comp-title">
                        {item.title}
                      </div>
                      <div className="comp-desc">
                        {item.desc}
                      </div>
                      <div className="comp-footer">
                        {item.name}
                      </div>
                      <img src={greenBG} alt="" className="abslute-bg" />
                    </div>
                    <a href={item.link} className='link' target='_blank'>

                      <img src={arrow} alt="" className="abslute-arrow" />
                    </a>
                  </div>
                </SwiperSlide>
              )}


            </Swiper>
            <div className="paginationContainer">
              <div className="swiper-button-prev" onClick={() => swiperRef.current.slidePrev()}>
                <img src={PrevBtn} alt="" />
              </div>
              <div className="swiper-button-next" onClick={() => swiperRef.current.slideNext()}>
                <img src={nextArrow} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurComponies;
