import React from 'react'

import { useTranslation } from 'react-i18next'

import ourvision from '../../../assets/imgs/bg/ourvision.svg'
import './OurVision.scss'

const OurVision = () => {
    const { t } = useTranslation();
    return (
        <div className="our-vision">

            <div style={{  height: "20px" }} id='our_vision'></div>
            {/* <div style={{ marginTop: "10px", height: "20px" }}></div>
            <div style={{ marginTop: "10px", height: "20px" }}></div> */}
            <div class='our_vision-div'>
                <div className="right-div" data-aos="zoom-in-left">
                    <img src={ourvision} alt="" />
                </div>
                <div></div>
                <div className="left-div" data-aos="zoom-in-right">
                    <p className='title'>{t("our_vision.title")}</p>
                    <div className="desc">
                        {/* نتطلع إلي تقديم خدمات مميزه بتعاملات راقية */}
                        {t("our_vision.desc")}
                    </div>
                </div>
            </div>
            <div style={{  height: "20px" }}></div>
        </div>
    )
}

export default OurVision
