import React from 'react'
import { useTranslation } from 'react-i18next'

import './OurPropose.scss'

import blackBg from '../../../assets/imgs/bg/OurPurpose.jpeg'

const OurPropose = () => {
    const { t } = useTranslation()

    return (
        <div >

            <div style={{ marginTop: "10px", height: "20px" }} id='our_propose'></div>
            <div style={{ marginTop: "10px", height: "20px" }}></div>
            <div style={{ marginTop: "10px", height: "20px" }}></div>
            <div className='our-propose' id='our_propose'>
                <div className="right-div" data-aos="fade-zoom-in" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="200">
                    <p className='title'>{t("our_goals.title")}</p>
                    <div className="desc" style={{width: "104%" }}>
                        {t("our_goals.desc1")}
                    </div>
                    <div className="desc2" style={{width: "104%" }}>
                        {t("our_goals.desc2")}
                    </div>
                    <div className="desc2" style={{width: "104%" }}>
                        {t("our_goals.desc3")}
                    </div>
                </div>
                <div className="left-div" data-aos="fade-up">
                    <img src={blackBg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default OurPropose