const lngs = [
  {
    code: 'en', // iso code
    // name: "English",
    icon:"assets/imgs/icons/english.png",
    name: 'English',
    country_code: 'us'
  },
  {
    code: 'ar', // iso code
    name: 'العربية',
    icon:"assets/imgs/icons/saudi-arabia.png",
    country_code: 'sa'
    // dir: "rtl"
  }
];

export default lngs;
