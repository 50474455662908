import React from "react";
import "./HeaderTop.css";
const HeaderTop = ({ head, text }) => {
  return (
    <div className="swiper-top-box">
      <h1>{head}</h1>
      <p>{text}</p>
    </div>
  );
};

export default HeaderTop;
