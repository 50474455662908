import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import LanguageButton from '../../common/language-button/LanguageButton';
// import Logo from '../../common/logo/Logo';
import Logo from '../../assets/imgs/bg/header-logo.svg';

import mainAppBarLinks from './mainAppBarLinks';

import './MainAppBarMd.scss';
import routerLinks from '../app/routerLinks';
import { Drawer, IconButton } from '@material-ui/core';

//import styles 👇
import 'react-modern-drawer/dist/index.css'

// import { styled, alpha } from '@mui/material/styles';
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

// const StyledMenu = styled((props) => (
//   <Menu
//     elevation={0}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'right'
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'right'
//     }}
//     {...props}
//   />
// ))(({ theme }) => ({
//   '& .MuiPaper-root': {
//     borderRadius: 6,
//     marginTop: theme.spacing(1),
//     minWidth: 180,
//     color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
//     boxShadow:
//       'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
//     '& .MuiMenu-list': {
//       padding: '4px 0'
//     },
//     '& .MuiMenuItem-root': {
//       '& .MuiSvgIcon-root': {
//         fontSize: 18,
//         color: theme.palette.text.secondary,
//         marginRight: theme.spacing(1.5)
//       },
//       '&:active': {
//         backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
//       }
//     }
//   }
// }));


const MainAppBarMd = ({ className }) => {
  const { t } = useTranslation();
  const windowWidth = useRef(window.innerWidth);

  const [drawerOpened, setDrawerOpened] = useState(false);
  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpened((prevState) => !prevState);
  };

  const [selectedSection, setSelectedSection] = React.useState('main-app-bar');
  const [offersSection, setOffersSection] = React.useState(
    document.querySelector(`.${selectedSection}`)
  );
  // React.useEffect(() => {
  //   setOffersSection(document.querySelector(`#${selectedSection}`));
  // }, [ selectedSection]);
  React.useEffect(() => {
    if (!drawerOpened && selectedSection !== '') {
      document.querySelector(`#${selectedSection}`).scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedSection]);

  const hanldeClick = (section) => () => {
    if (pathname === routerLinks.homePage && section !== '') {
      setSelectedSection(section);
      setDrawerOpened(false);
    } else {
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === routerLinks.homePage) {
    } else {
    }
  }, [pathname]);

  const renderNavLinks = () => {
    return (
      <ul>
        {mainAppBarLinks(t, pathname).map(({ id, name, link, section }) => (
          <li key={id}>
            <NavLink
              className="nav-link"
              activeClassName="active-link"
              to={link}
              exact
              onClick={hanldeClick(section)}>
              {name}
              <div className="active-img-wrap"></div>
            </NavLink>
          </li>
        ))}
                  <LanguageButton />

      </ul>
    );
  };

  return (
    <div className={className} id='main-app-bar'>
      <div className="custom-container">
        <div className="nav-content-wrapper">
          <div className="nav-lang-wrapper">
            <button
              // color="inherit"
              // aria-label="open drawer"
              style={{ backgroundColor: "#fff0" }}
              onClick={toggleDrawer()}
            // edge="start"
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0,0,256,256"
                style={{ fill: "#000000" }}>
                <g fill="#fcc419" ><g transform="scale(5.12,5.12)"><path d="M0,9v2h50v-2zM0,24v2h50v-2zM0,39v2h50v-2z"></path></g></g>
              </svg>
            </button>
          </div>
          <img src={Logo} alt="app logo" className={` `} />

          {/* <Logo className="main-app-bar-logo" /> */}
        </div>
      </div>
      <Drawer
        className="header-md-drawer"
        // anchor="right"
        open={drawerOpened}
        onClose={toggleDrawer()}>
        <div className="logo-links">
          <img src={Logo} alt="app logo" className={` `} style={{margin:"33px 22px"}}/>          {renderNavLinks()}
          {/* <a href="https://fatoorah.fatoorah.sa" target="_blank" rel="noreferrer">
            <button className="dashborad">{t('main_app_bar_links.Dashboard')}</button>
          </a> */}
          {/* <CustomizedMenus /> */}
        </div>
      </Drawer>
    </div>
  );
};

export default MainAppBarMd;
